<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="documentTitle" help-page-component="ProductTable"/>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-0 px-3>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <ProductTableList :supplier-id="supplierId" @list-loaded="listLoaded"/>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import ExportTable from "Components/Appic/ExportTable.vue";
import {mapFields} from "vuex-map-fields";
const ProductTableList = () => import('Components/Appic/ProductTableList')

export default {
    name: "ProductTable",
    props: ['supplierId'],
    components: {ProductTableList},
    data() {
        return {
            documentTitle: '',
            loader: false
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        listLoaded(retObj) {
            this.documentTitle = retObj.supplierName
            this.$title = this.$t('message.titles.productTable') + ': ' + retObj.supplierName
        }
    }
}
</script>

<style scoped>

</style>